.sidebar-title {
    color:white;
    font-weight: bold;
}

.sidebar-option {
    color:gray;
    font-weight: bold;
}

.overview-container {
    padding: 0 20px 0 20px;
}

@media (min-width: 900px) {
    .sidebar-toggle {
      display: none;
    }
  
    .sidebar {
      transform: translateX(0);
      position: static;
      width: 280px;
      height: auto;
      z-index: 1; /* Ensure it doesn't overlay on larger screens */
    }
  }

  /* Định dạng chung cho modal */
.custom-modal .modal-content {
  background-color: #212121;
  color: white;
  border-radius: 10px;
}

/* Header modal */
.custom-modal-header {
  background-color: #212121;
  border-bottom: 2px solid #9499ff;
}

.modal-title-text {
  color: #9499ff;
  font-size: 1.2rem;
}

/* Body modal */
.custom-modal-body {
  padding: 20px;
}

.notice-text {
  font-size: 1rem;
  color: red;
  font-weight: bold;
}

.modal-text {
  font-size: 1rem;
  color: #e0e0e0;
}

.enter-text {
  font-size: 1rem;
  color: #9499ff;
  margin-top: 15px;
}

/* Footer modal */
.custom-modal-footer {
  background-color: #212121;
  border-top: 2px solid #9499ff;
}

/* Nút bấm */
.custom-button {
  background-color: #9499ff;
  border: none;
  padding: 10px 20px;
  font-size: 1.2rem;
  transition: 0.3s;
}

.custom-button-cancel {
  background-color: red;
  border: none;
  padding: 10px 20px;
  font-size: 1.2rem;
  transition: 0.3s;
}

.custom-button:hover {
  background-color: #7a7ff5;
}

.custom-button-cancel:hover {
  background-color: #eb85f8;
}

/* Input nhập player key */
.player-key-input {
  width: 50%;
  padding: 12px 15px;
  font-size: 1rem;
  border-radius: 8px;
  border: 2px solid #9499ff;
  background-color: #2b2b2b;
  color: #7a7ff5;
  margin-top: 10px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.player-key-input::placeholder {
  color: #7a7ff5;
  opacity: 0.8;
  width: 50%;
}

.player-key-input:hover {
  border-color: #7a7ff5;
}

.player-key-input:focus {
  outline: none;
  border-color: #7a7ff5;
  box-shadow: 0 0 10px rgba(121, 127, 245, 0.5);
  width: 50%;
}


  