.btn-success {
  background-color: #9499ff; /* Màu xanh lá cây */
  border-color: #9499ff; /* Màu viền */
  margin: 10px;
}

.btn-success:hover {
  background-color: #abaedf; /* Màu xanh lá cây tối hơn khi di chuột qua */
  border-color: #abaedf; /* Màu viền tối hơn khi di chuột qua */
}

h2 {
  color: #9499ff;
}

p.cmdbeginner-description {
  color: #9499ff;
  padding-left: 10px;
}

.copied-command-cmdbeginner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  border-radius: 4px;
  border: '1px solid #ccc';
  width: 300px;
  box-sizing: border-box;
  padding: 6px 12px;
  font-weight: bold;
  margin-top: 15px;
  margin-left: 10px;
  cursor: copy
}
