/* Các quy tắc CSS hiện tại của bạn */

.sidebar-title {
  color: white;
  font-weight: bold;
}

.sidebar-option {
  color: gray;
  font-weight: bold;
  cursor: pointer;
}

.sidebar-option:hover {
  color: #9499FF;
  font-weight: bold;
  cursor: pointer;
}

.sidebar-option.selected {
  color: #9499FF;
  font-weight: bold;
}

.sidebar-logo {
  color: #9499FF;
  font-weight: bold;
  cursor: pointer;
}

.sidebar-selection {
  width: 100%;
}

hr {
  border-top: 1px solid gray;
  width: 100%;
}

.sidebar-toggle {
  display: block;
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;
  background-color: green;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #212121;
  padding-top: 20px;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  z-index: 2000; /* Ensure sidebar is above other components */
}

.sidebar.open {
  transform: translateX(0);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1500;
}

/* Thêm quy tắc CSS cho chế độ nằm ngang */
@media (orientation: landscape) {
  .sidebar {
    transform: translateX(-100%); /* Thu lại sidebar nếu nằm ngang */
  }

  .sidebar.open {
    transform: translateX(0); /* Hiện sidebar khi mở */
  }
}

@media (min-width: 900px) {
  .sidebar-toggle {
    display: none;
  }

  .sidebar {
    transform: translateX(0);
    position: static;
    width: 280px;
    height: auto;
    z-index: 1; /* Ensure it doesn't overlay on larger screens */
  }
}

@media (max-width: 787px) {
  .sidebar-toggle {
    display: block;
  }

  .sidebar {
    z-index: 2000; /* Ensure sidebar is above other components on mobile */
  }
}

.link {
  text-decoration: none;
}

/* Sidebar.css */

.sidebar {
  overflow-y: auto; /* Hiển thị thanh cuộn khi nội dung vượt quá chiều cao */
}

.sidebar-title {
  color: white;
  font-weight: bold;
}

.sidebar-option {
  color: gray;
  font-weight: bold;
}

.link {
  text-decoration: none; /* Không gạch chân */
}

.link:hover {
  text-decoration: underline; /* Gạch chân khi hover */
}

.sidebar-selection {
  margin-top: 20px; /* Khoảng cách cho Select */
}

