.title {
  width: 100%;
  color: #9499ff;
  font-weight: bold;
  margin-top: 30px;
}

.select {
  /* display: flex; */
  color: black;
  width: 100%;
  height: 40px;
  /* font-weight: bold; */
}

.command {
  /* display: flex; */
  color: black;
  width: 100%;
  height: 50px;
  font-weight: bold;
  font-size: 25px;
  padding-left: 10px;
  cursor: pointer;
}

.select-huh {
  /* display: flex; */
  color: black;
  width: 40%;
  /* font-weight: bold; */
}

.select-relics {
  /* display: flex; */
  color: black;
  width: 30%;
  /* font-weight: bold; */
}

.body {
  margin: 10px 10px;
}

.name-of-selection {
  color: white;
}
.LevelInput {
  padding: "8px";
  font-size: 16px;
  border-radius: 4px;
  border: "1px solid #ccc";
  box-sizing: "border-box";
  width: 100%;
  height: 40px;
}

.lightcones-content {
  justify-content: center;
  width: 65%;
}

.final-command {
  color: #9499ff;
  font-weight: bold;
}

.description {
  font-style: italic;
  color: red;
}
.outdated-message {
  font-style: italic;
  color: red;
}

.copied-command {
  font-size: 25px;
  width: 100%;
  font-weight: bold;
}

.spawn-function {
  color: red;
  font-style: italic;
}

.spawn-function-cmd {
  color: #9499ff;
  font-weight: bold;
  font-style: italic;
}
span.monster-name {
  color: cyan;
}

.select-subaffix {
  /* display: flex; */
  color: black;
  width: 100%;
}

p.name-of-selection-subaffix {
  color: white;
  width: 25%;
}

p.name-of-selection-step-subaffix {
  color: white;
  width: 100px;
  margin-left: 10px;
}

.step-subaffix-input {
  padding: "6px";
  font-size: "16px";
  border-radius: "4px";
  border: "1px solid #ccc";
  box-sizing: "border-box";
  width: 100%;
  height: 50px;
}

.step {
  color: white;
}

.character-image {
  width: 75%;
}

.notification {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: opacity 0.5s ease-in-out;
}

.relics-input {
  padding: 6px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
}
.command-container {
  padding: 20px;
}
.preview-main-stat {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  background-color: #f9f9f9;
  margin: 5px 0;
  display: flex; /* Sử dụng Flexbox */
  justify-content: space-between; /* Bố trí khoảng cách giữa hai phần tử */
  align-items: center; /* Căn chỉnh theo chiều dọc */
}
.preview-sub-stat {
  padding: 10px;
  background-color: #f9f9f9;
  margin: 5px 0;
  display: flex; /* Sử dụng Flexbox */
  justify-content: space-between; /* Bố trí khoảng cách giữa hai phần tử */
  align-items: center;
}
.step-controls {
  display: flex;
  align-items: center;
  justify-content: center; /* Căn giữa toàn bộ nội dung */
  margin-top: 8px;
}

.step-button {
  background-color: white; /* Đổi màu nền nút thành trắng */
  color: black; /* Đổi màu chữ nút thành đen */
  border: 1px solid #d9d9d9; /* Viền cho nút */
  margin: 0 8px; /* Căn lề cho các nút */
}

.step-input {
  width: 60px;
  text-align: center; /* Căn giữa nội dung trong ô nhập */
  background-color: #ffffff; /* Màu nền ô nhập */
  color: #000000; /* Màu chữ trong ô nhập */
  border: 1px solid #d9d9d9; /* Viền cho ô nhập */
}

.button-excute-command {
  color: white;
  background-color: #9499ff;
  font-weight: 500;
  border-radius: 0.5rem;
  font-size: 1rem;
  line-height: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  cursor: pointer;
  text-align: center;
  margin-right: 0.5rem;
  display: inline-flex;
  align-items: center;
  border: none;
  height: 60%;
}

.button-excute-command:hover {
  background-color: #9499ff;
}

.button-excute-command svg {
  display: inline;
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 0.75rem;
  color: white;
  background-color: #9499ff;
}

.button-excute-command:focus svg {
  animation: spin_357 0.5s linear;
}

@keyframes spin_357 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.button-excute-command-lightcone {
  color: white;
  background-color: #9499ff;
  font-weight: 500;
  border-radius: 0.5rem;
  font-size: 1rem;
  line-height: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  cursor: pointer;
  text-align: center;
  margin-right: 0.5rem;
  display: inline-flex;
  align-items: center;
  border: none;
  height: 90%;
}

.button-excute-command-lightcone:hover {
  background-color: #9499ff;
}

.button-excute-command-lightcone svg {
  display: inline;
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 0.75rem;
  color: white;
  background-color: #9499ff;
}

.button-excute-command-lightcone:focus svg {
  animation: spin_357 0.5s linear;
}

@keyframes spin_357 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
